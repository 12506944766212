import React from "react"
import { Container, Button, Jumbotron } from "react-bootstrap"
import Layout from "../components/layout"

export default ({ data }) => {
  return (
    <Layout>
    <Container fluid>
      <Jumbotron>
        <h1>Hello, world!</h1>
        <p>
          Don't let your memes be dreams!
        </p>
        <p>
          <Button variant="primary">Risk it for the biscuit!</Button>
        </p>
      </Jumbotron>
    </Container>
  </Layout>
  )
}
